import { TreeItem } from 'hooks/useSiteTree';

const getItemsWithTitles = (treeItemList: TreeItem[], parentTitle: string) => {
  const resultList: TreeItem[] = [];

  treeItemList.forEach((treeItem) => {
    const title = parentTitle ? treeItem.title.replace(`${parentTitle} `, '') : treeItem.title;

    if (treeItem.children.length === 0) {
      const item = { ...treeItem, title };

      resultList.push(item);
    } else {
      const preparedParentTitle = treeItem.path
        .slice(1, -1)
        .replace('-', ' ')
        .split(' ')
        .map((value) => value.charAt(0).toUpperCase() + value.slice(1))
        .join(' ');

      const itemWithChildren = {
        ...treeItem,
        children: getItemsWithTitles(treeItem.children, preparedParentTitle),
      };

      resultList.push(itemWithChildren);
    }
  });

  return resultList;
};

const getTreeItemsWithProperTitles = (list: TreeItem[]) => {
  const preparedTreeItemList = [{ ...list[0], children: [] }, ...list[0].children];

  return getItemsWithTitles(preparedTreeItemList, '');
};

export default getTreeItemsWithProperTitles;
