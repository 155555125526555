import React, { FC } from 'react';

import ColorTopBar from 'components/ColorTopBar';
import ListTree from 'components/ListTree';
import getTreeItemsWithProperTitles from 'utils/getTreeItemsWithProperTitles';

import { SitemapProps } from './models';

import './Sitemap.scss';

const Sitemap: FC<SitemapProps> = ({ title, tree }) => {
  const properTreeItems = getTreeItemsWithProperTitles(tree);

  return (
    <>
      <ColorTopBar />
      <div className="sitemap-container">
        <h1 className="sitemap-container__title">{title}</h1>
        <div className="sitemap-container__links">
          <ListTree items={properTreeItems} />
        </div>
      </div>
    </>
  );
};

export default Sitemap;
